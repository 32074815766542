@import 'variables';
@import 'mixins';

/* ==============================================================================================

    Universal utility classes for use throughout the app.

    TABLE OF CONTENTS:

    GENERAL
    CONTAINERS
    GRIDS
    TYPOGRAPHY
      TEXT HEIGHT
      TEXT COLOR
      TEXT TRANSFORM
      TEXT SIZE
      TEXT ALIGNMENT
      TEXT WEIGHT
    BACKGROUNDS
    SCROLLING
    POSITIONING
    ROTATION
    REACT ANIMATIONS
    TAILWIND STYLE UTILS

   ============================================================================================== */

/* GENERAL
------------------------------------------------------------------------------------------------- */

.hide,
.hidden {
  display: none !important;
}
.hidden-mobile {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
}
.hidden-desktop {
  @media (min-width: 1025px) {
    display: none;
  }
}
.o0 {
  opacity: 0;
}
.o1 {
  opacity: 1;
}
.fade-md {
  transition: opacity 300ms;
}
.min-height-1-line {
  min-height: $font-size-base * $line-height-base + 1;
}

.maskable {
  position: relative;
  &:not(.unmasked) {
    max-height: 120px;
    overflow: hidden;
  }
}
.maskable-mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.maskable-mask-fade {
  height: 60px;
  @include gradient-y(rgba(#fff, 0), #fff);
}
.maskable-mask a {
  display: block;
  background-color: #fff;
  color: $brand-color-secondary-blue;
  font-family: $custom-font-book;
  text-transform: uppercase;
  text-decoration: underline;
}
// Mobile only version
.m-maskable .maskable-mask {
  display: none;
}
@include mobile() {
  .m-maskable {
    position: relative;
    &:not(.unmasked) {
      max-height: 120px;
      overflow: hidden;
    }
  }
  .m-maskable .maskable-mask {
    display: block;
  }
}

.no-line-break,
.wsnw {
  white-space: nowrap;
}
.bordered {
  border: 1px solid $global-color-black;
}
.cp {
  cursor: pointer;
}
.cm {
  cursor: move;
}
.vh {
  visibility: hidden;
}
.no-outline {
  &,
  &:focus,
  &:hover,
  &:focus:hover {
    outline: none !important;
  }
}
.no-underline {
  &,
  &:focus,
  &:hover,
  &:focus:hover {
    text-decoration: none !important;
  }
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

// Fix for Quick Links headers disappearing under nav on legal pages.
.jump-link-spacer {
  h2:before,
  h3:before {
    display: block;
    content: ' ';
    margin-top: -200px;
    height: 200px;
    visibility: hidden;
  }
}

// Padding for images in support pages.
.support-img {
  padding: 30px;
}

// Flip horizontally
.flip-horizontal {
  transform: scaleX(-1);
  -ms-filter: 'FlipH';
  filter: 'FlipH';
}

// Hide spinner buttons on number inputs.
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.required:before {
  content: '*';
  color: $global-color-error-red;
  padding-right: 2px;
}

/* CONTAINERS
------------------------------------------------------------------------------------------------- */

// BS 4 has a `.no-gutters` (plural) class that removes padding from columns but ALSO removes
// the negative margin from the row.  So we still need our `.no-gutter` for now.
.no-gutter > [class*='col-'],
.no-gutter > .col {
  padding-right: 0;
  padding-left: 0;
}

@include mobile {
  .m-no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  .m-row {
    @include make-row();
  }
}

.max-w-min {
  max-width: min-content;
}
.\!max-w-min {
  max-width: min-content !important;
}

.page-wrapper {
  max-width: 970px;
}

// Always square container with border
.square {
  border: 1px solid $brand-color-secondary-blue;
  width: 100%;
  padding: 50%;
  box-sizing: border-box;
  position: relative;
}
.square-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.square-body--centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Bordered Sections
.sections .section {
  border-bottom: 2px solid #efefef;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

// Border Tail - Add an arrow below container.
.border-tail:after {
  @include border-tail(#fff);
}
@include mobile {
  .border-tail--mobile:after {
    @include border-tail(#fff);
  }
}

// Video Wrapper
// - Achieves fluid width sizing and auto height keeping ratio at 16:9.
//   Wrap youtube embeds with one of these.
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Split
.split {
  display: table;
  width: 100%;
}
.split--fixed {
  table-layout: fixed;
}

.split-box {
  display: table-cell;
  vertical-align: middle;
}

.split-box-right {
  text-align: right;
}

.mw-iphone-5 {
  max-width: 320px;
}

.collapsible.collapsed .collapsible-body {
  display: none;
}

.flex-cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-col {
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-cols.align-items--center {
  align-items: center;
}

@include tablet {
  .flex-cols.flex-cols--2 .flex-col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-cols.flex-cols--3 .flex-col {
    flex-basis: 33%;
    max-width: 33%;
  }
  .flex-cols.flex-cols--4 .flex-col {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.fading {
  opacity: 0.3;
}

.w-10 {
  width: 2.5rem;
}
.w-40 {
  width: 10rem;
}
.w-64 {
  width: 16rem;
}
.w-80 {
  width: 20rem;
}

.\!w-80 {
  width: 20rem !important;
}
.w-\[140\] {
  width: 140px;
}
@include mobile {
  .mb\:w-\[140\] {
    width: 140px;
  }
}
.w-\[120\] {
  width: 120px;
}
@include mobile {
  .mb\:w-\[120\] {
    width: 120px;
  }
}

@include mobile {
  .mb\:h-\[213px\] {
    height: 416px;
  }
}

@include mobile {
  .mb\:h-\[426px\] {
    height: 416px;
  }
}

@include tablet {
  .md\:h-\[578px\] {
    height: 578px;
  }
}

.h-10 {
  height: 2.5rem;
}
.h-40 {
  height: 10rem;
}
.h-32 {
  height: 8rem;
}

.w-full {
  width: 100%;
}

@include mobile {
  .mb\:w-full {
    width: 100%;
  }
}

@include tablet {
  .md\:w-full {
    width: 100%;
  }
}

/* GRIDS - CSS grids - css-tricks.com/snippets/css/complete-guide-grid
------------------------------------------------------------------------------------------------- */

// grid-sm-3 - CSS grid replacing bootstrap column grids.
.grid-sm-3 {
  margin-left: -15px;
  margin-right: -15px;
}

@include tablet {
  // A tablet only three column grid (4 x 4 x 4).
  .grid-sm-3 {
    display: -ms-grid;
    display: grid;
    grid-template: repeat(9, auto) / repeat(3, 33.33%);
    -ms-grid-rows: auto auto auto auto auto auto auto auto auto;
    -ms-grid-columns: 33.33% 33.33% 33.33%;
  }

  // A tablet only 2 column grid (6 x 6).
  .grid-sm-2 {
    display: -ms-grid;
    display: grid;
    grid-template: repeat(9, auto) / repeat(2, 50%);
    -ms-grid-rows: auto auto auto auto auto auto auto auto auto;
    -ms-grid-columns: 50% 50%;
  }
}

/* TYPOGRAPHY
------------------------------------------------------------------------------------------------- */

.select-all {
  user-select: all;
}

// Text typography styles
.text-display-xl {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 70px;
  line-height: 1;
}
@include tablet {
  .text-display-xl {
    font-size: 80px;
  }
}
@include desktop {
  .text-display-xl {
    font-size: 100px;
  }
}
.text-display-lg sup {
  font-size: 12px;
  top: -3.7em;
}
@include tablet {
  .text-display-lg sup {
    font-size: 12px;
    top: -4.3em;
  }
}
@include desktop {
  .text-display-lg sup {
    font-size: 12px;
    top: -5.4em;
  }
}

.text-display-lg {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include tablet {
  .text-display-lg {
    font-size: 48px;
  }
}
@include desktop {
  .text-display-lg {
    font-size: 60px;
  }
}
.text-display-lg sup {
  font-size: 24px;
}
@include tablet {
  .text-display-lg sup {
    font-size: 28px;
  }
}
@include desktop {
  .text-display-lg sup {
    font-size: 36px;
  }
}
.text-display-sm {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include tablet {
  .text-display-sm {
    font-size: 40px;
  }
}
@include desktop {
  .text-display-sm {
    font-size: 48px;
  }
}
.text-display-sm sup {
  font-size: 22px;
}
@include tablet {
  .text-display-sm sup {
    font-size: 24px;
  }
}
@include desktop {
  .text-display-sm sup {
    font-size: 28px;
  }
}
.text-headline-xl {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include tablet {
  .text-headline-xl {
    font-size: 36px;
  }
}
@include desktop {
  .text-headline-xl {
    font-size: 40px;
  }
}
.text-headline-xl sup {
  font-size: 18px;
}
@include tablet {
  .text-headline-xl sup {
    font-size: 22px;
  }
}
@include desktop {
  .text-headline-xl sup {
    font-size: 24px;
  }
}
.text-headline-lg {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include tablet {
  .text-headline-lg {
    font-size: 28px;
  }
}
@include desktop {
  .text-headline-lg {
    font-size: 36px;
  }
}
.text-headline-lg sup {
  font-size: 14px;
}
@include tablet {
  .text-headline-lg sup {
    font-size: 18px;
  }
}
@include desktop {
  .text-headline-lg sup {
    font-size: 22px;
  }
}
.text-headline-md {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include tablet {
  .text-headline-md {
    font-size: 24px;
  }
}
@include desktop {
  .text-headline-md {
    font-size: 28px;
  }
}
.text-headline-md sup {
  font-size: 12px;
}
@include tablet {
  .text-headline-md sup {
    font-size: 14px;
  }
}
@include desktop {
  .text-headline-md sup {
    font-size: 18px;
  }
}
.text-headline-sm {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.5;
}
@include desktop {
  .text-headline-sm {
    font-size: 24px;
  }
}
.text-headline-sm sup {
  font-size: 12px;
}
@include tablet {
  .text-headline-sm sup {
    font-size: 12px;
  }
}
@include desktop {
  .text-headline-sm sup {
    font-size: 14px;
  }
}
.text-body-lg,
.text-body-bold-lg {
  font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 1.5;
}
.text-body-lg sup,
.text-body-bold-lg sup {
  font-size: 12px;
}
.text-body-md,
.text-body-bold-md {
  font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.75;
}
.text-body-md sup,
.text-body-bold-md sup {
  font-size: 10px;
}
.text-body-sm,
.text-body-bold-sm {
  font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.71;
}
.text-body-sm sup,
.text-body-bold-sm sup {
  font-size: 8px;
}
.text-body-legal,
.text-body-bold-legal {
  font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.66;
}
.text-body-legal sup,
.text-body-bold-legal sup {
  font-size: 6px;
}
.text-body-bold-lg,
.text-body-bold-md,
.text-body-bold-sm,
.text-body-bold-legal {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;
}
.\!text-body-bold-legal {
  font-family: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif !important;
  font-size: 12px;
  line-height: 1.66;
}

// == Breakpoint variants for text font styles
@include tablet {
  .md\:text-body-lg {
    font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 1.5;
  }
  .md\:text-body-lg sup {
    font-size: 12px;
  }
  .md\:text-body-md,
  .md\:text-body-bold-md {
    font-family: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.75;
  }
  .md\:text-body-md sup,
  .md\:text-body-bold-md sup {
    font-size: 10px;
  }
}

//== TEXT HEIGHT

.text-height-sm {
  line-height: 1.2;
}
.text-height-default {
  line-height: $line-height-base;
}
.text-height-lg {
  line-height: 1.67;
}
.text-height-xl {
  line-height: 2;
}

//== TEXT COLOR

.text-white {
  color: $global-color-white;
}
.text-gray-100 {
  color: $global-color-gray-100;
}
.text-gray-300 {
  color: $global-color-gray-300;
}
.text-gray-600 {
  color: $global-color-gray-600;
}
.text-gray-700 {
  color: $global-color-gray-700
}
.text-dark {
  color: $global-color-black !important;
}
.text-primary-blue {
  color: $brand-color-primary-blue;
}
.text-blue-50 {
  color: $global-color-blue-50
}
.text-secondary-blue,
.text-secondary,
.tw-text-sapphire-700 {
  color: $brand-color-secondary-blue;
}
.text-sapphire-800 {
  color: $global-color-sapphire-800
}
.text-red,
.text-error,
.text-error-red {
  color: $global-color-error-red;
}
.text-sale-red,
.text-promo,
.text-promo-red,
{
  color: $global-color-promo-red;
}
.text-emerald-700 {
  color: $global-color-emerald-700
}

.text-success,
.text-complete {
  color: $global-color-complete-green;
}

// Svg coloring
.sn-svg.-white {
  fill: #fff;
}

//== TEXT TRANSFORM

.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-sentence {
  text-transform: none !important;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-underline {
  text-decoration: underline;
}
.text-strike {
  text-decoration: line-through;
}
.text-italic {
  font-style: italic;
}
.wbba {
  word-break: break-all;
}

//== TEXT SIZE

.legal-copy {
  font-size: $sn-font-size-xs;
}

//== TEXT ALIGNMENT

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

//== TEXT WEIGHT

.text-weight-black,
.text-weight-heavy,
.text-bold {
  font-family: $custom-font-black;
  font-weight: normal;
}
.text-weight-book {
  font-family: $custom-font-book;
}

.fwb {
  font-weight: bold;
}
.fwn {
  font-weight: normal;
}

/* BACKGROUNDS
------------------------------------------------------------------------------------------------- */

.shader {
  background-color: #000;
  opacity: 0;
  overflow: hidden;
  width: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.shader--1440 {
  max-width: 1440px;
}

@include mobile {
  .shader {
    width: 100%;
  }
}

.shader--1 {
  opacity: 0.1;
}
.shader--2 {
  opacity: 0.2;
}
.shader--3 {
  opacity: 0.3;
}
.shader--4 {
  opacity: 0.4;
}
.shader--5 {
  opacity: 0.5;
}

.bg-primary-blue {
  background-color: $brand-color-primary-blue;
}
.bg-secondary-blue {
  background-color: $brand-color-secondary-blue;
}
.bg-sapphire-800 {
  background-color: $global-color-sapphire-800;
}
.bg-blue-50,
.bg-light-blue {
  background-color: $global-color-blue-50;
}
.bg-emerald-700 {
  background-color: $global-color-emerald-700;
}
.bg-sale-red,
.bg-promo-red {
  background-color: $global-color-promo-red;
}
.bg-error-red {
  background-color: $global-color-error-red;
}
.bg-white {
  background-color: $global-color-white;
}
.bg-light-gray,
.bg-gray-100 {
  background-color: $global-color-gray-100;
}
.bg-gray,
.bg-gray-300 {
  background-color: $global-color-gray-300;
}
.bg-dark-gray,
.bg-gray-600 {
  background-color: $global-color-gray-600;
}
.bg-gray-700 {
  background-color: $global-color-gray-700;
}
.bg-black {
  background-color: $global-color-black;
}
.bg-transparent {
  background-color: transparent;
}

// BG gradients based off of colors defined in variables.scss
.bg-gradient-primary-blue {
  @include bg-gradient($bgcolor: $brand-color-primary-blue, $transparency: 0.8);
}

.hover-bg-white:focus,
.hover-bg-white:hover {
  background-color: #fff;
}

/* SCROLLING
------------------------------------------------------------------------------------------------- */

.scroll-xl,
.scroll-lg,
.scroll-md,
.scroll-sm {
  overflow-y: auto;
}
.scroll-xl {
  max-height: 600px;
}
.scroll-lg {
  max-height: 350px;
}
.scroll-md {
  max-height: 200px;
}
.scroll-sm {
  max-height: 160px;
}

// Desktop + tablet only
@include tablet {
  .d-scroll-xl,
  .d-scroll-lg,
  .d-scroll-md,
  .d-scroll-sm {
    overflow-y: auto;
  }
  .d-scroll-xl {
    max-height: 600px;
  }
  .d-scroll-lg {
    max-height: 350px;
  }
  .d-scroll-md {
    max-height: 200px;
  }
  .d-scroll-sm {
    max-height: 160px;
  }
}

// Synced side-scrolling containers
// @see sn.ui.js #Scroll synced elements
@include mobile {
  .synced-scrollers {
    padding: 0;
    white-space: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .synced-scroller {
    float: none;
    display: inline-block;
    position: relative;
    min-width: 160px;
    white-space: normal;
    vertical-align: top;
  }
}
.synced-scrollers-all-bp {
  padding: 0;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}

.synced-scroller-all-bp {
  float: none;
  display: inline-block;
  position: relative;
  min-width: 160px;
  white-space: normal;
  vertical-align: top;
}

/* POSITIONING
------------------------------------------------------------------------------------------------- */

// Extending bs util to specific breakpoints
@each $property, $value in $grid-breakpoints {
  @media (min-width: $value) {
    .#{$property}-sticky-top {
      @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
      }
    }
  }
}

.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.db {
  display: block !important;
}
.di {
  display: inline !important;
}
.dib {
  display: inline-block !important;
}
.d-contents {
  display: contents;
}
.pr {
  position: relative !important;
}
.m-pr {
  @include mobile {
    position: relative !important;
  }
}
.pa {
  position: absolute !important;
}

.l0 {
  left: 0 !important;
}
.l100 {
  left: 100% !important;
}
.r0 {
  right: 0 !important;
}
.r100 {
  right: 100% !important;
}
.t0 {
  top: 0 !important;
}
.t100 {
  top: 100% !important;
}
.b0 {
  bottom: 0 !important;
}
.b100 {
  bottom: 100% !important;
}

.tac,
.table.tac thead th {
  text-align: center !important;
}
.tal {
  text-align: left !important;
}
.tar {
  text-align: right !important;
}

.vam {
  vertical-align: middle !important;
}
.vat {
  vertical-align: top !important;
}
.vab {
  vertical-align: bottom !important;
}
.vatt {
  vertical-align: text-top !important;
}
.vatb {
  vertical-align: text-bottom !important;
}

// Mobile only positioning, add more as needed.
@include mobile {
  .m-tal {
    text-align: left;
  }
  .m-tac {
    text-align: center;
  }
  .m-tar {
    text-align: right;
  }

  .m-db {
    display: block;
  }
  .m-dib {
    display: inline-block;
  }
}

// Desktop + tablet only positioning
@include tablet {
  .d-tal {
    text-align: left;
  }
  .d-tac {
    text-align: center;
  }
  .d-tar {
    text-align: right;
  }

  .d-db {
    display: block;
  }
  .d-dib {
    display: inline-block;
  }
}

.v-center-block {
  @include v-center-block();
}

.align-center {
  align-items: center;
}

/* ROTATION
------------------------------------------------------------------------------------------------- */

.rotate-ccw-nw {
  transform: rotate(-45deg);
}
.rotate-ccw-w {
  transform: rotate(-90deg);
}
.rotate-ccw-sw {
  transform: rotate(-135deg);
}
.rotate-ccw-s {
  transform: rotate(-180deg);
}

/* REACT ANIMATIONS - used by CSSTransition from 'react-transition-group' package.
------------------------------------------------------------------------------------------------- */
// fade in out
.fade-in-out-enter {
  opacity: 0.01;
}
.fade-in-out-enter.fade-in-out-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-in-out-exit {
  opacity: 1;
}
.fade-in-out-exit.fade-in-out-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// slide in out
.slide-in-out-enter {
  max-height: 1%;
  padding: 0;
}
.slide-in-out-enter.slide-in-out-enter-active {
  max-height: 100%;
  padding: initial;
  transition: all 500ms ease-in;
}
.slide-in-out-exit {
  max-height: 100%;
  padding: initial;
}
.slide-in-out-exit.slide-in-out-exit-active {
  max-height: 1%;
  padding: 0;
  transition: all 300ms ease-in;
}

// slide under
.slide-under-enter {
  transform: translateY(-100%);
}
.slide-under-enter.slide-under-enter-active {
  transform: translateY(0);
  transition: transform 150ms ease-in;
}
.slide-under-exit {
  transform: translateY(0);
}
.slide-under-exit.slide-under-exit-active {
  transform: translateY(-100%);
  transition: transform 125ms ease-in;
}

.animate-slide-left {
  animation: tw-slide-left 250ms cubic-bezier(0.4, 0, 1, 1) forwards;
}

.animate-slide-right {
  animation: tw-slide-right 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.animate-slide-out {
  animation: tw-slide-out 250ms cubic-bezier(0.4, 0, 1, 1) forwards;
}

.animate-slide-up-translate {
  animation: slide-up-translate 300ms linear forwards;
}

.animate-slide-down-translate {
  animation: slide-down-translate 300ms linear forwards;
}

@keyframes tw-slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes tw-slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes tw-slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-up-translate {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down-translate {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@import 'tailwind';
