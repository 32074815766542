@import 'imports';

/* ==============================================================================================

   BASE - Defines base styles for basic elements.

   Table of Contents:

   HTML & BODY
     HTML
     BODY
     HEADERS
   FONTS
   TYPOGRAPHY
   CONTAINERS
   WORKAREA ADMIN BAR
   PRINT

   ============================================================================================== */

/* HTML & BODY
------------------------------------------------------------------------------------------------- */

//== HTML

html {
  // Adjusting from the browser default (usually 16px).
  // Setting it here, not on body, because `rem` units
  // reference the "root" element, aka the <html> element.
  font-size: 16px; // 16 on root so our math works out better, 14 on body

  sup {
    font-size: 0.57142857em; // 8px at 14px font-size
  }
}

//== BODY
body {
  font-size: $sn-font-default; // 14px
  text-size-adjust: none;
  background-color: $global-color-white;

  @include nav-collapsed {
    &.-with-admin-toolbar {
      padding-top: $header-height-m + $wa-admin-bar-height;
    }
    &.-skip-nav-show {
      padding-top: $header-height-m + $skip-nav-link-height;
    }
    & {
      padding-top: $header-height-m;
    }
  }

  @include nav-expanded {
    &.-with-admin-toolbar {
      padding-top: $header-height-d + $wa-admin-bar-height;
    }
    &.-skip-nav-show {
      padding-top: $header-height-d + $skip-nav-link-height;
    }
    &.-with-admin-toolbar.-skip-nav-show {
      padding-top: $header-height-d + $wa-admin-bar-height +
        $skip-nav-link-height;
    }
    & {
      padding-top: $header-height-d;
    }
  }

  @include mobile {
    &.-with-admin-toolbar {
      padding-top: $header-height-m;
    }
    &.-skip-nav-show {
      padding-top: $header-height-m + $skip-nav-link-height;
    }
  }

  // Hide admin bar
  &.-hidden-admin-toolbar {
    .admin-toolbar-portal {
      display: none;
    }
  }
}

//== HEADERS
.page-header {
  background-color: white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-fixed;
  // Site header that can hide/show on scroll
  transition: transform 0.25s ease-in-out;
  will-change: transform;
}

@include tablet {
  body.-with-admin-toolbar .page-header {
    top: $wa_admin_bar_height;
  }
}

body.-unpinned-header .page-header {
  transform: translateY(-100%);
}

// Animate all sticky bars the same way.
.top-below-header,
.sticky-promo,
.sticky-summary,
.sticky-bar,
.navbar-light--sn-subnav {
  transition: top 0.25s ease-in-out;
}

// When unpinned, secondary nav items like sticky bars should be fixed to the top.
body.-unpinned-header {
  .sticky-promo,
  .sticky-summary,
  .sticky-bar,
  .navbar-light--sn-subnav {
    top: 0;
  }
}
@include tablet {
  body.-unpinned-header.-with-admin-toolbar {
    .sticky-promo,
    .sticky-summary,
    .sticky-bar,
    .navbar-light--sn-subnav {
      top: $wa_admin_bar_height;
    }
  }
}

// We are solving two problems by setting 'top' instead of letting them
// be laid out vertically by the normal flow:
// 1. Defeat some incorrect hard-coded top values in cms components for promos.
// 2. If you don't set a top, the transition will not animate.
.page-body {
  .sticky-promo,
  .sticky-summary,
  .sticky-bar {
    top: $header_height_m;
    @include tablet {
      top: $header_height_d;
    }
  }
}

// Mobile only version
@include mobile {
  .page-body .m-sticky-bar {
    transition: top 0.25s ease-in-out;
  }

  .page-body .m-sticky-bar {
    top: $header_height_m;
  }

  body.-unpinned-header .page-body {
    .m-sticky-bar {
      top: 0;
    }
  }
}

body.content-preview-visibility {
  padding-top: 0;
}

/* FONTS
------------------------------------------------------------------------------------------------- */
@font-face {
  font-family: 'Avenir LT W01 45 Book';
  src: url('../fonts/avenir/avenir-45-book.eot?#iefix');
  src:
    url('../fonts/avenir/avenir-45-book.eot?#iefix') format('eot'),
    url('../fonts/avenir/avenir-45-book.woff2') format('woff2'),
    url('../fonts/avenir/avenir-45-book.woff') format('woff'),
    url('../fonts/avenir/avenir-45-book.ttf') format('truetype'),
    url('../fonts/avenir/avenir-45-book.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4')
      format('svg');
  unicode-range: U+000-5FF;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir LT W01 95 Black';
  src: url('../fonts/avenir/avenir-95-black.eot?#iefix');
  src:
    url('../fonts/avenir/avenir-95-black.eot?#iefix') format('eot'),
    url('../fonts/avenir/avenir-95-black.woff2') format('woff2'),
    url('../fonts/avenir/avenir-95-black.woff') format('woff'),
    url('../fonts/avenir/avenir-95-black.ttf') format('truetype'),
    url('../fonts/avenir/avenir-95-black.svg#7bdad4a5-289b-42f9-b6fa-c5883b9e9f23')
      format('svg');
  unicode-range: U+000-5FF;
  font-display: swap;
}
.text-book {
  font-family: $custom-font-book;
}
.text-black {
  font-family: $custom-font-black;
}
.text-disabled,
.tw-text-disabled {
  color: #9ca3af;
}
.tw-line-through {
  text-decoration: line-through;
}

/* TYPOGRAPHY - links and decorators
------------------------------------------------------------------------------------------------- */
a {
  color: $brand-color-secondary-blue;
  text-decoration: underline;

  &:hover {
    color: $brand-color-secondary-blue;
  }
  &:focus,
  &:active {
    @include link-focus-indicator();
  }
  &:focus:not(.focus-visible),
  &:focus:not(:focus-visible),
  &:active:not(.focus-visible),
  &:active:not(:focus-visible) {
    text-decoration: underline;
    outline: none;
    box-shadow: none;
  }
}

.link {
  text-decoration: none;
  color: $brand-color-secondary-blue;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $brand-color-secondary-blue;
    text-decoration: underline;
  }
}

b,
strong {
  font-family: $custom-font-black;
  font-weight: normal;
}

/* CONTAINERS
------------------------------------------------------------------------------------------------- */

// Sticky elements that should be below the header bar or top of viewport if
// the header has been slid up.
.top-below-header {
  // Some global elements like video play buttons, slick arrows have z-index of 1
  z-index: 2;

  // Prevent header from overlapping subnavs during animation
  top: $header-height-m;

  body.-unpinned-header & {
    top: 0;
  }

  @include nav-expanded {
    body & {
      top: $header-height-d;
    }
    body.-skip-nav-show & {
      top: $header-height-d + $skip-nav-link-height;
    }
    body.-unpinned-header & {
      top: 0;
    }
    body.-with-admin-toolbar.-skip-nav-show & {
      top: $wa-admin-bar-height + $header-height-d + $skip-nav-link-height;
    }
    body.-with-admin-toolbar & {
      top: $wa-admin-bar-height + $header-height-d;
    }
    body.-with-admin-toolbar.-unpinned-header & {
      top: $wa-admin-bar-height;
    }
  }

  @include nav-collapsed {
    body & {
      top: $header-height-m;
    }
    body.-skip-nav-show & {
      top: $header-height-m + $skip-nav-link-height;
    }
    body.-unpinned-header & {
      top: 0;
    }
    body.-with-admin-toolbar & {
      top: $wa-admin-bar-height + $header-height-m;
    }
    body.-with-admin-toolbar.-unpinned-header & {
      top: $wa-admin-bar-height;
    }
  }

  // Admin toolbar isn't visible on mobile
  // so we don't need to account for it
  @include mobile {
    body.-with-admin-toolbar & {
      top: $header-height-m;
    }
    body.-with-admin-toolbar.-unpinned-header & {
      top: 0;
    }
  }
}

.container.-mw1440 {
  @include make-container();
  margin: 0 auto;
  max-width: 1440px;
}

.container.-mw1920 {
  @include make-container();
  margin: 0 auto;
  max-width: 1920px;
}

/* WORKAREA ADMIN BAR
------------------------------------------------------------------------------------------------- */

.admin-toolbar {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    position: fixed;
    top: 0;
    z-index: calc(#{$zindex-fixed} + 1);
    width: 100%;
    height: $wa-admin-bar-height;
    background: white;
    border: 0;
    box-shadow: 0 0 5px 0 rgba(#000, 0.15);
  }
}

.admin-toolbar--expanded {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.underlined-sup {
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 8px;
  left: -0.5px;
}

/* PRINT
------------------------------------------------------------------------------------------------- */

@media print {
  @page {
    size: Letter;
    margin: 0.5in;
  }
}

/* UTILITIES
------------------------------------------------------------------------------------------------- */

.zindex-dropdown {
  z-index: $zindex-dropdown;
}
.zindex-sticky {
  z-index: $zindex-sticky;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-tooltip {
  z-index: $zindex-tooltip;
}
.zindex-chat-button {
  z-index: 107158;
}
.zindex-over-chat {
  z-index: 107159;
}
