@import 'variables';
@import 'mixins';

/* TAILWIND STYLE UTILS
   See https://tailwindcss.com/docs/margin
------------------------------------------------------------------------------------------------- */

$size-values: [0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48,
  56, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240, 256, 288, 320,
  384];
/* prettier-ignore */
$size-labels: [ '0', 'px', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '5',
  '6', '7', '8', '9', '10', '11', '12', '14', '16', '20', '24', '28', '32', '36', '40', '44',
  '48', '52', '56', '60', '64', '72', '80', '96' ];

@for $index from 1 through length($size-values) {
  $value: '#{nth($size-values, $index)}px';
  $label: str-replace(nth($size-labels, $index), '.', '\\.');

  // Margin
  .tw-m-#{$label} {
    margin: #{$value};
  }
  .tw-ml-#{$label} {
    margin-left: #{$value};
  }
  .tw-mt-#{$label} {
    margin-top: #{$value};
  }
  .tw-mb-#{$label} {
    margin-bottom: #{$value};
  }
  .tw-mr-#{$label},
  .\[\&\:not\(\:last-child\)\]\:tw-mr-#{$label}:not(:last-child) {
    margin-right: #{$value};
  }
  .tw-mx-#{$label} {
    margin-left: #{$value};
    margin-right: #{$value};
  }
  .tw-my-#{$label} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }

  .\!tw-m-#{$label} {
    margin: #{$value} !important;
  }
  .\!tw-ml-#{$label} {
    margin-left: #{$value} !important;
  }
  .\!tw-mt-#{$label} {
    margin-top: #{$value} !important;
  }
  .\!tw-mb-#{$label} {
    margin-bottom: #{$value} !important;
  }
  .\!tw-mr-#{$label} {
    margin-right: #{$value} !important;
  }
  .\!tw-mx-#{$label} {
    margin-left: #{$value} !important;
    margin-right: #{$value} !important;
  }
  .\!tw-my-#{$label} {
    margin-top: #{$value} !important;
    margin-bottom: #{$value} !important;
  }

  // Padding
  .tw-p-#{$label} {
    padding: #{$value};
  }
  .tw-pl-#{$label} {
    padding-left: #{$value};
  }
  .tw-pt-#{$label} {
    padding-top: #{$value};
  }
  .tw-pb-#{$label} {
    padding-bottom: #{$value};
  }
  .tw-pr-#{$label} {
    padding-right: #{$value};
  }
  .tw-px-#{$label} {
    padding-left: #{$value};
    padding-right: #{$value};
  }
  .tw-py-#{$label} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }

  .\!tw-p-#{$label} {
    padding: #{$value} !important;
  }
  .\!tw-pl-#{$label} {
    padding-left: #{$value} !important;
  }
  .\!tw-pt-#{$label} {
    padding-top: #{$value} !important;
  }
  .\!tw-pb-#{$label} {
    padding-bottom: #{$value} !important;
  }
  .\!tw-pr-#{$label} {
    padding-right: #{$value} !important;
  }
  .\!tw-px-#{$label} {
    padding-left: #{$value} !important;
    padding-right: #{$value} !important;
  }
  .\!tw-py-#{$label} {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }

  // Width
  .tw-w-#{$label} {
    width: #{$value};
  }
  .\!tw-w-#{$label} {
    width: #{$value} !important;
  }
  // Min Width
  .tw-min-w-#{$label} {
    min-width: #{$value};
  }
  .\!tw-min-w-#{$label} {
    min-width: #{$value} !important;
  }

  // Max Width
  .tw-max-w-#{$label} {
    max-width: #{$value};
  }
  .\!tw-max-w-#{$label} {
    max-width: #{$value} !important;
  }

  // Height
  .tw-h-#{$label} {
    height: #{$value};
  }
  .\!tw-h-#{$label} {
    height: #{$value} !important;
  }

  // Min Height
  .tw-min-h-#{$label} {
    min-height: #{$value};
  }
  .\!tw-min-h-#{$label} {
    min-height: #{$value} !important;
  }

  // Max Height
  .tw-max-h-#{$label} {
    max-height: #{$value};
  }
  .\!tw-max-h-#{$label} {
    max-height: #{$value} !important;
  }

  // Position
  .tw-inset-#{$label} {
    inset: #{$value};
  }
  .tw-inset-x-#{$label} {
    left: #{$value};
    right: #{$value};
  }
  .tw-inset-y-#{$label} {
    top: #{$value};
    bottom: #{$value};
  }
  .tw-start-#{$label} {
    inset-inline-start: #{$value};
  }
  .tw-end-#{$label} {
    inset-inline-end: #{$value};
  }
  .tw-top-#{$label} {
    top: #{$value};
  }
  .tw-bottom-#{$label} {
    bottom: #{$value};
  }
  .tw-left-#{$label} {
    left: #{$value};
  }
  .tw-right-#{$label} {
    right: #{$value};
  }

  // Translate
  .tw-translate-x-#{$label} {
    transform: translateX(#{$value});
  }
  .tw-translate-y-#{$label} {
    transform: translateY(#{$value});
  }
}

@for $index from 1 through length($size-values) {
  $value: '#{nth($size-values, $index)}px';
  $label: str-replace(nth($size-labels, $index), '.', '\\.');

  @include mobile {
    // Margin
    .mb\:tw-m-#{$label} {
      margin: #{$value};
    }
    .mb\:tw-ml-#{$label} {
      margin-left: #{$value};
    }
    .mb\:tw-mt-#{$label} {
      margin-top: #{$value};
    }
    .mb\:tw-mb-#{$label} {
      margin-bottom: #{$value};
    }
    .mb\:tw-mr-#{$label} {
      margin-right: #{$value};
    }
    .mb\:tw-mx-#{$label} {
      margin-left: #{$value};
      margin-right: #{$value};
    }
    .mb\:tw-my-#{$label} {
      margin-top: #{$value};
      margin-bottom: #{$value};
    }

    // Padding
    .mb\:tw-p-#{$label} {
      padding: #{$value};
    }
    .mb\:tw-pl-#{$label} {
      padding-left: #{$value};
    }
    .mb\:tw-pt-#{$label} {
      padding-top: #{$value};
    }
    .mb\:tw-pb-#{$label} {
      padding-bottom: #{$value};
    }
    .mb\:tw-pr-#{$label} {
      padding-right: #{$value};
    }
    .mb\:tw-px-#{$label} {
      padding-left: #{$value};
      padding-right: #{$value};
    }
    .mb\:tw-py-#{$label} {
      padding-top: #{$value};
      padding-bottom: #{$value};
    }

    // Width
    .mb\:tw-w-#{$label} {
      width: #{$value};
    }
    .mb\:\!tw-w-#{$label} {
      width: #{$value} !important;
    }

    // Height
    .mb\:tw-h-#{$label} {
      height: #{$value};
    }
    .mb\:\!tw-h-#{$label} {
      height: #{$value} !important;
    }

    // Min Height
    .mb\:tw-min-h-#{$label} {
      min-height: #{$value};
    }
    .mb\:\!tw-min-h-#{$label} {
      min-height: #{$value} !important;
    }

    // Position
    .mb\:tw-inset-#{$label} {
      inset: #{$value};
    }
    .mb\:tw-inset-x-#{$label} {
      left: #{$value};
      right: #{$value};
    }
    .mb\:tw-inset-y-#{$label} {
      top: #{$value};
      bottom: #{$value};
    }
    .mb\:tw-start-#{$label} {
      inset-inline-start: #{$value};
    }
    .mb\:tw-end-#{$label} {
      inset-inline-end: #{$value};
    }
    .mb\:tw-top-#{$label} {
      top: #{$value};
    }
    .mb\:tw-bottom-#{$label} {
      bottom: #{$value};
    }
    .mb\:tw-left-#{$label} {
      left: #{$value};
    }
    .mb\:tw-right-#{$label} {
      right: #{$value};
    }

    // Translate
    .mb\:tw-translate-x-#{$label} {
      transform: translateX(#{$value});
    }
    .mb\:tw-translate-y-#{$label} {
      transform: translateY(#{$value});
    }

    // Width
    .mb\:tw-w-#{$label} {
      width: #{$value};
    }
    .mb\:\!tw-w-#{$label} {
      width: #{$value} !important;
    }

    .mb\:tw-max-w-#{$label} {
      max-width: #{$value};
    }
    .mb\:\!tw-max-w-#{$label} {
      max-width: #{$value} !important;
    }

    // Height
    .mb\:\tw-h-#{$label} {
      height: #{$value};
    }
    .mb\:\!tw-h-#{$label} {
      height: #{$value} !important;
    }

    .mb\:\tw-max-h-#{$label} {
      max-height: #{$value};
    }
    .mb\:\!tw-max-h-#{$label} {
      max-height: #{$value} !important;
    }
  }

  @include tablet {
    // Margin
    .md\:tw-m-#{$label} {
      margin: #{$value};
    }
    .md\:tw-ml-#{$label} {
      margin-left: #{$value};
    }
    .md\:tw-mt-#{$label} {
      margin-top: #{$value};
    }
    .md\:tw-mb-#{$label} {
      margin-bottom: #{$value};
    }
    .md\:tw-mr-#{$label} {
      margin-right: #{$value};
    }
    .md\:tw-mx-#{$label} {
      margin-left: #{$value};
      margin-right: #{$value};
    }
    .md\:tw-my-#{$label} {
      margin-top: #{$value};
      margin-bottom: #{$value};
    }

    // Max Height
    .md\:tw-max-h-#{$label} {
      max-height: #{$value};
    }
    .md\:\!tw-max-h-#{$label} {
      max-height: #{$value} !important;
    }

    // Padding
    .md\:tw-p-#{$label} {
      padding: #{$value};
    }
    .md\:tw-pl-#{$label} {
      padding-left: #{$value};
    }
    .md\:tw-pt-#{$label} {
      padding-top: #{$value};
    }
    .md\:tw-pb-#{$label} {
      padding-bottom: #{$value};
    }
    .md\:tw-pr-#{$label} {
      padding-right: #{$value};
    }
    .md\:tw-px-#{$label} {
      padding-left: #{$value};
      padding-right: #{$value};
    }
    .md\:tw-py-#{$label} {
      padding-top: #{$value};
      padding-bottom: #{$value};
    }

    // Position
    .md\:tw-inset-#{$label} {
      inset: #{$value};
    }
    .md\:tw-inset-x-#{$label} {
      left: #{$value};
      right: #{$value};
    }
    .md\:tw-inset-y-#{$label} {
      top: #{$value};
      bottom: #{$value};
    }
    .md\:tw-start-#{$label} {
      inset-inline-start: #{$value};
    }
    .md\:tw-end-#{$label} {
      inset-inline-end: #{$value};
    }
    .md\:tw-top-#{$label} {
      top: #{$value};
    }
    .md\:tw-bottom-#{$label} {
      bottom: #{$value};
    }
    .md\:tw-left-#{$label} {
      left: #{$value};
    }
    .md\:tw-right-#{$label} {
      right: #{$value};
    }

    // Translate
    .md\:tw-translate-x-#{$label} {
      transform: translateX(#{$value});
    }
    .md\:tw-translate-y-#{$label} {
      transform: translateY(#{$value});
    }

    // Width
    .md\:tw-w-#{$label} {
      width: #{$value};
    }
    .md\:\!tw-w-#{$label} {
      width: #{$value} !important;
    }

    // Height
    .md\:\tw-h-#{$label} {
      height: #{$value};
    }
    .md\:\!tw-h-#{$label} {
      height: #{$value} !important;
    }
  }

  @include desktop {
    // Margin
    .lg\:tw-m-#{$label} {
      margin: #{$value};
    }
    .lg\:tw-ml-#{$label} {
      margin-left: #{$value};
    }
    .lg\:tw-mt-#{$label} {
      margin-top: #{$value};
    }
    .lg\:tw-mb-#{$label} {
      margin-bottom: #{$value};
    }
    .lg\:tw-mr-#{$label} {
      margin-right: #{$value};
    }
    .lg\:tw-mx-#{$label} {
      margin-left: #{$value};
      margin-right: #{$value};
    }
    .lg\:tw-my-#{$label} {
      margin-top: #{$value};
      margin-bottom: #{$value};
    }

    // Max Height
    .lg\:tw-max-h-#{$label} {
      max-height: #{$value};
    }
    .lg\:\!tw-max-h-#{$label} {
      max-height: #{$value} !important;
    }

    // Padding
    .lg\:tw-p-#{$label} {
      padding: #{$value};
    }
    .lg\:tw-pl-#{$label} {
      padding-left: #{$value};
    }
    .lg\:tw-pt-#{$label} {
      padding-top: #{$value};
    }
    .lg\:tw-pb-#{$label} {
      padding-bottom: #{$value};
    }
    .lg\:tw-pr-#{$label} {
      padding-right: #{$value};
    }
    .lg\:tw-px-#{$label} {
      padding-left: #{$value};
      padding-right: #{$value};
    }
    .lg\:tw-py-#{$label} {
      padding-top: #{$value};
      padding-bottom: #{$value};
    }

    // Position
    .lg\:tw-inset-#{$label} {
      inset: #{$value};
    }
    .lg\:tw-inset-x-#{$label} {
      left: #{$value};
      right: #{$value};
    }
    .lg\:tw-inset-y-#{$label} {
      top: #{$value};
      bottom: #{$value};
    }
    .lg\:tw-start-#{$label} {
      inset-inline-start: #{$value};
    }
    .lg\:tw-end-#{$label} {
      inset-inline-end: #{$value};
    }
    .lg\:tw-top-#{$label} {
      top: #{$value};
    }
    .lg\:tw-bottom-#{$label} {
      bottom: #{$value};
    }
    .lg\:tw-left-#{$label} {
      left: #{$value};
    }
    .lg\:tw-right-#{$label} {
      right: #{$value};
    }

    // Translate
    .lg\:tw-translate-x-#{$label} {
      transform: translateX(#{$value});
    }
    .lg\:tw-translate-y-#{$label} {
      transform: translateY(#{$value});
    }

    // Width
    .lg\:tw-w-#{$label} {
      width: #{$value};
    }
    .lg\:\!tw-w-#{$label} {
      width: #{$value} !important;
    }

    // Height
    .lg\:\tw-h-#{$label} {
      height: #{$value};
    }
    .lg\:\!tw-h-#{$label} {
      height: #{$value} !important;
    }
  }
}

// Widths
.tw-w-1\/2 {
  width: 50%;
}

@include tablet {
  .md\:tw-w-1\/2 {
    width: 50%;
  }
}

// Position
.tw-relative {
  position: relative;
}
.tw-absolute {
  position: absolute;
}

// Text colors
.tw-text-white {
  color: white;
}
.tw-text-gray-100 {
  color: #f3f4f6;
}
.tw-text-gray-400 {
  color: #9ca3af;
}
.tw-text-gray-600 {
  color: #4b5563;
}
.tw-text-gray-700 {
  color: #374151;
}
.\!tw-text-gray-700 {
  color: #374151 !important;
}
.tw-text-gray-950 {
  color: #030712;
}
.tw-text-blue-300 {
  color: #93c5fd;
}
.tw-text-sapphire-700 {
  color: #1472d7;
}
.tw-text-red-700 {
  color: #b91c1c;
}
.tw-text-emerald-700 {
  color: #047857;
}
.tw-text-amber-700 {
  color: #b45309;
}

// Text alignment
.tw-text-left {
  text-align: left;
}
.tw-text-right {
  text-align: right;
}
@include tablet {
  .md\:tw-text-right {
    text-align: right;
  }
}

.tw-italic {
  font-style: italic;
}
.\!tw-no-underline {
  text-decoration-line: none !important;
}
.\!hover\:tw-underline:hover {
  text-decoration-line: underline !important;
}

// Backgrounds
.tw-bg-white {
  background-color: rgb(255 255 255);
}
.tw-bg-gray-50 {
  background-color: #f9fafb;
}
.tw-bg-gray-100 {
  background-color: #f3f4f6;
}
.tw-bg-gray-200 {
  background-color: #e5e7eb;
}
.tw-bg-gray-600 {
  background-color: #4b5563;
}
.tw-bg-sapphire-50 {
  background-color: #f6fcfe;
}
.tw-bg-sapphire-100 {
  background-color: #e3f4fc;
}
.tw-bg-sapphire-700 {
  background-color: #1472d7;
}
.tw-bg-red-100 {
  background-color: #fee2e2;
}
.tw-bg-red-600 {
  background-color: #dc2626;
}
.tw-bg-emerald-100 {
  background-color: #d1fae5;
}
.tw-bg-emerald-700 {
  background-color: #047857;
}
.tw-bg-amber-100 {
  background-color: #fef3c7;
}
.tw-bg-amber-700 {
  background-color: #b45309;
}

.hover\:tw-bg-sapphire-100:hover {
  background-color: #e3f4fc;
}
.hover\:tw-bg-gray-100:hover {
  background-color: #f3f4f6;
}

// Borders
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

.tw-border-0 {
  border-width: 0;
}
.tw-border {
  border-width: 1px;
}
.tw-border-2,
.hover\:tw-border-2:hover {
  border-width: 2px;
}

.tw-border-r {
  border-right-width: 1px;
}
.tw-border-t {
  border-top-width: 1px;
}
.tw-border-b,
.\[\&\:not\(\:last-child\)\]\:tw-border-b:not(:last-child),
.\[\&\:last-child\]\:tw-border-b:last-child {
  border-bottom-width: 1px;
}

.tw-border-white {
  border-color: rgb(255 255 255);
}
.tw-border-gray-100 {
  border-color: #f3f4f6;
}
.tw-border-b-gray-100 {
  border-bottom-color: rgba(243, 244, 246, 1);
}
.tw-border-gray-200 {
  border-color: rgb(229, 231, 235);
}
.tw-border-gray-300 {
  border-color: rgb(209, 213, 219);
}
.tw-border-gray-600 {
  border-color: rgb(75 85 99);
}
.tw-border-b-gray-200 {
  border-bottom-color: rgb(229, 231, 235);
}
.tw-border-sapphire-300 {
  border-color: #99d1f5;
}
.tw-border-red-300 {
  border-color: #fca5a5;
}
.tw-border-emerald-300 {
  border-color: #6ee7b7;
}
.tw-border-amber-300 {
  border-color: #fcd34d;
}

// Grid
.tw-grid {
  display: grid;
}

@include tablet {
  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.tw-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
@include tablet {
  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@include desktop {
  .lg\:tw-grid-cols-\[363px_1fr\] {
    grid-template-columns: 363px 1fr;
  }
  .lg\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@include mobile() {
  .mb\:tw-row-start-1 {
    grid-row-start: 1;
  }
  .mb\:tw-row-start-2 {
    grid-row-start: 2;
  }
}

@include desktop {
  .lg\:tw-col-start-1 {
    grid-column-start: 1;
  }
  .lg\:tw-col-end-3 {
    grid-column-end: 3;
  }
}

.tw-gap-0\.5 {
  gap: 0.125rem; /* 2px */
}

.tw-gap-2 {
  gap: 0.5rem; /* 8px */
}

.tw-gap-3 {
  gap: 0.75rem; /* 12px */
}

.tw-gap-4 {
  gap: 1rem; /* 16px */
}
.tw-gap-6 {
  gap: 1.5rem; /* 24px */
}

.tw-gap-8 {
  gap: 2rem; /* 32px */
}

@include tablet() {
  .md\:tw-gap-4 {
    gap: 1rem; /* 16px */
  }
  .md\:tw-gap-8 {
    gap: 2rem; /* 32px */
  }
}

// Background Size
.tw-bg-cover {
  background-size: cover;
}
.tw-bg-contain {
  background-size: contain;
}

// Background Position
.tw-bg-top {
  background-position: top;
}
.tw-bg-right {
  background-position: right;
}
.tw-bg-bottom {
  background-position: bottom;
}
.tw-bg-left {
  background-position: left;
}
.tw-bg-center {
  background-position: center;
}

// Opacity
.tw-opacity-0 {
  opacity: 0;
}
.tw-opacity-75,
.hover\:tw-opacity-75:hover {
  opacity: 0.75;
}
.tw-opacity-100 {
  opacity: 1;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.tw-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.tw-duration-300 {
  transition-duration: 300ms;
}
.tw-duration-1000 {
  transition-duration: 1000ms;
}

.tw-outline-none,
.focus-visible\:tw-outline-none:focus-visible,
.focus\:tw-outline-none:focus,
.active\:tw-outline-none:active {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-shadow-soft {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.tw-shadow-md {
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.tw-shadow-xl {
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.focus-visible\:tw-shadow-focus:focus-visible {
  box-shadow:
    0 0 0 2px white,
    0 0 0 3px $brand-color-secondary-blue;
}

// TW custom one-off arbitrary values

.tw-w-\[298px\] {
  width: 298px;
}

@include tablet {
  // md:tw-w-[calc(50%_-_15px)
  .md\:tw-w-\[calc\(50\%_-_15px\)\] {
    width: calc(50% - 15px);
  }

  //  md:tw-w-[286px]
  .md\:tw-w-\[286px\] {
    width: 286px;
  }

  // md:even:tw-left-[calc(50%_+_15px)]
  .md\:even\:tw-left-\[calc\(50\%_\+_15px\)\]:nth-of-type(even) {
    left: calc(50% + 15px) !important;
  }
}

.focus\:tw-shadow-\[inset_0px_0px_0px_4px_\#e3f4fc\]:focus {
  box-shadow: inset 0 0 0 4px #e3f4fc; // TW: Sapphire-100
}

.\[\&\:not\(\:last-child\)\]\:tw-border-b:not(:last-child) {
  border-bottom-width: 1px;
}

.tw-max-h-\[120px\] {
  max-height: 120px;
}

// Flexbox
.tw-flex {
  display: flex;
}
.tw-justify-center {
  justify-content: center;
}
.tw-justify-between {
  justify-content: space-between;
}
.tw-justify-end {
  justify-content: flex-end;
}
.tw-items-center {
  align-items: center;
}

// Flex Direction
.tw-flex-row {
  flex-direction: row;
}

@include tablet {
  .md\:tw-flex-row {
    flex-direction: row;
  }
}

.tw-flex-col {
  flex-direction: column;
}

.hover\:tw-py-\[7px\]:hover {
  padding-top: 7px;
  padding-bottom: 7px;
}

.hover\:tw-px-\[11px\]:hover {
  padding-right: 11px;
  padding-left: 11px;
}

.tw-translate-x-full {
  transform: translateX(100%);
}
.tw-translate-y-full {
  transform: translateY(100%);
}

// Border radius
.tw-rounded-none {
  border-radius: 0;
}
.tw-rounded-full {
  border-radius: 50%;
}
.tw-rounded-sm {
  border-radius: 0.125rem; /* 2px */
}
.tw-rounded {
  border-radius: 0.25rem; /* 4px */
}
.tw-rounded-md {
  border-radius: 0.375rem; /* 6px */
}
.tw-rounded-lg {
  border-radius: 0.5rem; /* 8px */
}
.tw-rounded-xl {
  border-radius: 0.75rem; /* 12px */
}
.tw-rounded-2xl {
  border-radius: 1rem; /* 16px */
}

.tw-rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tw-rounded-t-sm {
  border-top-left-radius: 0.125rem; /* 2px */
  border-top-right-radius: 0.125rem; /* 2px */
}
.tw-rounded-t {
  border-top-left-radius: 0.25rem; /* 4px */
  border-top-right-radius: 0.25rem; /* 4px */
}
.tw-rounded-t-md {
  border-top-left-radius: 0.375rem; /* 6px */
  border-top-right-radius: 0.375rem; /* 6px */
}
.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem; /* 8px */
  border-top-right-radius: 0.5rem; /* 8px */
}
.tw-rounded-t-xl {
  border-top-left-radius: 0.75rem; /* 12px */
  border-top-right-radius: 0.75rem; /* 12px */
}
.tw-rounded-t-2xl {
  border-top-left-radius: 1rem; /* 16px */
  border-top-right-radius: 1rem; /* 16px */
}

.tw-rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tw-rounded-l-sm {
  border-top-left-radius: 0.125rem; /* 2px */
  border-bottom-left-radius: 0.125rem; /* 2px */
}
.tw-rounded-l {
  border-top-left-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem; /* 4px */
}
.tw-rounded-l-md {
  border-top-left-radius: 0.375rem; /* 6px */
  border-bottom-left-radius: 0.375rem; /* 6px */
}
.tw-rounded-l-lg {
  border-top-left-radius: 0.5rem; /* 8px */
  border-bottom-left-radius: 0.5rem; /* 8px */
}
.tw-rounded-l-xl {
  border-top-left-radius: 0.75rem; /* 12px */
  border-bottom-left-radius: 0.75rem; /* 12px */
}
.tw-rounded-tl-lg {
  border-top-left-radius: 0.5rem; /* 8px */
}
.tw-rounded-tr-lg {
  border-top-right-radius: 0.5rem; /* 8px */
}
.tw-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem; /* 8px */
}
.tw-rounded-br-lg {
  border-bottom-right-radius: 0.5rem; /* 8px */
}
.tw-rounded-b-lg {
  border-bottom-left-radius: 0.5rem; /* 8px */
  border-bottom-right-radius: 0.5rem; /* 8px */
}

@include mobile {
  .mb\:tw-flex {
    display: flex;
  }
  .mb\:tw-contents {
    display: contents;
  }
  .mb\:tw-justify-between {
    justify-content: space-between;
  }
  .mb\:tw-justify-center {
    justify-content: center;
  }
  .mb\:tw-justify-end {
    justify-content: flex-end;
  }
  .mb\:tw-absolute {
    position: absolute;
  }

  .mb\:tw-translate-x-full {
    transform: translateX(100%);
  }
  .mb\:tw-translate-y-full {
    transform: translateY(100%);
  }

  // Border radius
  .mb\:tw-rounded-none {
    border-radius: 0;
  }
  .mb\:tw-rounded-sm {
    border-radius: 0.125rem; /* 2px */
  }
  .mb\:tw-rounded {
    border-radius: 0.25rem; /* 4px */
  }
  .mb\:tw-rounded-md {
    border-radius: 0.375rem; /* 6px */
  }
  .mb\:tw-rounded-lg {
    border-radius: 0.5rem; /* 8px */
  }
  .mb\:tw-rounded-xl {
    border-radius: 0.75rem; /* 12px */
  }

  .mb\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .mb\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem; /* 2px */
    border-top-right-radius: 0.125rem; /* 2px */
  }
  .mb\:tw-rounded-t {
    border-top-left-radius: 0.25rem; /* 4px */
    border-top-right-radius: 0.25rem; /* 4px */
  }
  .mb\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem; /* 6px */
    border-top-right-radius: 0.375rem; /* 6px */
  }
  .mb\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem; /* 8px */
    border-top-right-radius: 0.5rem; /* 8px */
  }
  .mb\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem; /* 12px */
    border-top-right-radius: 0.75rem; /* 12px */
  }
  .mb\:tw-rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .mb\:tw-rounded-b-sm {
    border-bottom-left-radius: 0.125rem; /* 2px */
    border-bottom-right-radius: 0.125rem; /* 2px */
  }
  .mb\:tw-rounded-b {
    border-bottom-left-radius: 0.25rem; /* 4px */
    border-bottom-right-radius: 0.25rem; /* 4px */
  }
  .mb\:tw-rounded-b-md {
    border-bottom-left-radius: 0.375rem; /* 6px */
    border-bottom-right-radius: 0.375rem; /* 6px */
  }
  .mb\:tw-rounded-b-lg {
    border-bottom-left-radius: 0.5rem; /* 8px */
    border-bottom-right-radius: 0.5rem; /* 8px */
  }
  .mb\:tw-rounded-b-xl {
    border-bottom-left-radius: 0.75rem; /* 12px */
    border-bottom-right-radius: 0.75rem; /* 12px */
  }
  .mb\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .mb\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem; /* 2px */
    border-bottom-left-radius: 0.125rem; /* 2px */
  }
  .mb\:tw-rounded-l {
    border-top-left-radius: 0.25rem; /* 4px */
    border-bottom-left-radius: 0.25rem; /* 4px */
  }
  .mb\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem; /* 6px */
    border-bottom-left-radius: 0.375rem; /* 6px */
  }
  .mb\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem; /* 8px */
    border-bottom-left-radius: 0.5rem; /* 8px */
  }
  .mb\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem; /* 12px */
    border-bottom-left-radius: 0.75rem; /* 12px */
  }
}

@include tablet {
  // Display
  .md\:tw-flex {
    display: flex;
  }
  .md\:tw-inline-block {
    display: inline-block;
  }
  .md\:tw-contents {
    display: contents;
  }
  .md\:\!tw-contents {
    display: contents !important;
  }
  .md\:tw-justify-between {
    justify-content: space-between;
  }
  .md\:tw-justify-center {
    justify-content: center;
  }
  .md\:tw-justify-end {
    justify-content: flex-end;
  }
  .md\:tw-absolute {
    position: absolute;
  }

  .md\:tw-translate-x-full {
    transform: translateX(100%);
  }
  .md\:tw-translate-y-full {
    transform: translateY(100%);
  }

  // Border radius
  .md\:tw-rounded-none {
    border-radius: 0;
  }
  .md\:tw-rounded-sm {
    border-radius: 0.125rem; /* 2px */
  }
  .md\:tw-rounded {
    border-radius: 0.25rem; /* 4px */
  }
  .md\:tw-rounded-md {
    border-radius: 0.375rem; /* 6px */
  }
  .md\:tw-rounded-lg {
    border-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-xl {
    border-radius: 0.75rem; /* 12px */
  }

  .md\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .md\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem; /* 2px */
    border-top-right-radius: 0.125rem; /* 2px */
  }
  .md\:tw-rounded-t {
    border-top-left-radius: 0.25rem; /* 4px */
    border-top-right-radius: 0.25rem; /* 4px */
  }
  .md\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem; /* 6px */
    border-top-right-radius: 0.375rem; /* 6px */
  }
  .md\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem; /* 8px */
    border-top-right-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem; /* 12px */
    border-top-right-radius: 0.75rem; /* 12px */
  }

  .md\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem; /* 2px */
    border-bottom-left-radius: 0.125rem; /* 2px */
  }
  .md\:tw-rounded-l {
    border-top-left-radius: 0.25rem; /* 4px */
    border-bottom-left-radius: 0.25rem; /* 4px */
  }
  .md\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem; /* 6px */
    border-bottom-left-radius: 0.375rem; /* 6px */
  }
  .md\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem; /* 8px */
    border-bottom-left-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem; /* 12px */
    border-bottom-left-radius: 0.75rem; /* 12px */
  }
  .md\:tw-rounded-tl-lg {
    border-top-left-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-tr-lg {
    border-top-right-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem; /* 8px */
  }
  .md\:tw-rounded-br-lg {
    border-bottom-right-radius: 0.5rem; /* 8px */
  }
}

.tw-object-cover {
  object-fit: cover;
}

.tw-left-\[50px\] {
  left: 50px;
}

.tw-top-1\/2 {
  top: 50%;
}
.tw-right-1\/2 {
  right: 50%;
}
.tw-left-1\/2 {
  left: 50%;
}
.tw-bottom-1\/2 {
  bottom: 50%;
}

.tw-overflow-hidden {
  overflow: hidden;
}
