@import 'variables'; /* handles bootstrap variables as well */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

/* ==============================================================================================

   MIXINS

   ============================================================================================== */

/* BREAK POINTS - desktop, tablet, and mobile
------------------------------------------------------------------------------------------------- */

@mixin desktop {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin tablet {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin mobile {
  @include media-breakpoint-down(sm) {
    @content;
  }
}

//== Mega Nav Breakpoints
// For use with the mega nav
// as it changes from desktop -> mobile at an odd breakpoint

@mixin nav-collapsed {
  @media (max-width: 1159px) {
    @content;
  }
}

@mixin nav-expanded {
  @media (min-width: 1160px) {
    @content;
  }
}

//== Crowdtwist Breakpoints
// For use only in crowdtwist.scss
//   * CT widgets are inside an iframe that itself is wrapped in BS padding
//   * Calculate px value of REM, because root size of iframe differs

@mixin mobile-ct {
  @media (max-width: map_get($grid-breakpoints, sm) - 2 * $sn-font-default) {
    @content;
  }
}

@mixin tablet-ct {
  @media (min-width: map_get($grid_breakpoints, sm) - 2 * $sn-font-default) {
    @content;
  }
}

@mixin desktop-ct {
  @media (min-width: map_get($grid_breakpoints, lg) - (2 * $sn-font-default)) {
    @content;
  }
}

/* BOOTSTRAP REPLACEMENTS
------------------------------------------------------------------------------------------------- */

//== sn-button-variant()
// Replacement for the bootstrap `button-variant` mixin so we can just
// specify what we need/want on all buttons.
@mixin sn-button-variant(
  $color,
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  box-shadow: 0 2px 1px 0 rgba(6, 16, 29, 0.08);
  text-decoration: none;

  @include hover() {
    color: $color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  // remove outline on mouse click
  &:focus {
    outline: none;
  }

  // Keyboard focus, (not mouse clicks), add ring
  &:focus.focus-visible,
  &:focus:focus-visible {
    box-shadow: 0 0 0 4px #b3d8fa;
  }
}

/* POSITIONING
------------------------------------------------------------------------------------------------- */

//== center-block()
// Translate the block to the center vertically
// and horizontally.
@mixin center-block($left: null, $right: null) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//== v-center-block()
// Translate the block to the center vertically
// and set the `left` or `right` if provided.
@mixin v-center-block($left: null, $right: null) {
  position: absolute;
  @if variable-exists(left) {
    left: $left;
  }
  @if variable-exists(right) {
    right: $right;
  }
  top: 50%;
  transform: translateY(-50%);
}

//== h-center-block()
// Translate the block to the center horizontally
// and set the `top` if provided.
@mixin h-center-block($top: null) {
  position: absolute;
  @if variable-exists(top) {
    top: $top;
  }
  left: 50%;
  transform: translateX(-50%);
}

/* BACKGROUNDS
------------------------------------------------------------------------------------------------- */

//== bg-center-cover()
// Uses a background image to cover an element
@mixin bg-center-cover($img) {
  background: url($img) no-repeat center center scroll;
  background-size: cover;
}

//== bg-gradient()
@mixin bg-gradient($bgcolor: $brand-color-secondary-blue, $transparency: 0.5) {
  background-color: $bgcolor;
  @include gradient-x-three-colors(
    $start-color: $bgcolor,
    $mid-color: transparentize($global-color-white, $transparency),
    $end-color: $bgcolor
  );
}

/* BORDERS
------------------------------------------------------------------------------------------------- */

@mixin border-tail($color, $height: 18px, $width: 15px) {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid $height $color;
  border-left: solid $width transparent;
  border-right: solid $width transparent;
  z-index: 1;
}

@mixin no-outline() {
  &.active.focus,
  &.active:focus,
  &:active.focus,
  &:active:focus,
  &.focus,
  &:focus {
    outline: none !important;
  }
}

/* MISC
------------------------------------------------------------------------------------------------- */

//== link-focus-indicator()
@mixin link-focus-indicator() {
  box-shadow: 0 0 0 2px white, 0 0 0 3px $brand-color-secondary-blue;
  outline: none !important;
  text-decoration: underline;
}

//== calculatePx()
// returns pixel value of rem
@function calculatePx($remSize) {
  $px: $sn-font-default * $remSize;
  @return #{$px}px;
}

/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}
