/* ==============================================================================================

   VARIABLES

   Table of Contents:

   SLEEPNUMBER VARIABLES
      Colors
      Fonts
      Font Sizes
      Containers
      Header Nav
      Assets
   SLICK CAROUSEL VARIABLES
   BOOTSTRAP VARIABLES
      Colors
      Body
      Links
      Grid Breakpoints
      Grid Containers
      Components
      Typography
      Buttons + Forms
   BOOTSTRAP VARIABLES IMPORT

   We have to override bootstrap's variables before importing them.
   See https://getbootstrap.com/docs/4.4/getting-started/theming/#variable-defaults

   ============================================================================================== */

/* SLEEPNUMBER VARIABLES (Only ux blessed variables are migrated from the old platform)
------------------------------------------------------------------------------------------------- */

//== Colors
$brand-color-primary-blue: #133156;
$brand-color-secondary-blue: #1472d7;
$global-color-sapphire-800: #0f52a4;
$global-color-blue-50: #f1f7fd;

$global-color-white: #ffffff;
$global-color-gray-100: #f3f4f6; // old grey 1
$global-color-gray-300: #d1d5db; // old grey 2
$global-color-gray-600: #4b5563; // old grey 3
$global-color-gray-700: #374151;
$global-color-black: #000000;

$global-color-promo-red: #d8222d;
$global-color-error-red: #b91c1c;

$global-color-vikings-yellow: #ffc62f;
$global-color-vikings-purple: #592c82;
$global-color-success-green: #41bb78;
$global-color-complete-green: #059669; // emerald-600
$global-color-emerald-700: #047857;
$global-color-warning-orange: #d97706;

// Nav colors
$nav-item-hover: $brand-color-secondary-blue;
$nav-menu-border: $global-color-white;
$nav-sub-items: $global-color-gray-100;
$nav-bg-color: $brand-color-primary-blue;

//== Fonts (Avenir)
$custom-font-book: 'Avenir LT W01 45 Book', Helvetica, Arial, sans-serif;
$custom-font-black: 'Avenir LT W01 95 Black', Helvetica, Arial, sans-serif;

//== Font Sizes

$sn-font-default: 14px;

// Prefixed with 'sn' since bootstrap also uses 'font-size-' variable names.
$sn-font-size-xs: 12px;
$sn-font-size-sm: 14px;
$sn-font-size-default: 16px;
$sn-font-size-md: 18px;
$sn-font-size-md-2: 22px;
$sn-font-size-lg: 24px;
$sn-font-size-lg-2: 28px;
$sn-font-size-lg-3: 34px;
$sn-font-size-lg-4: 40px;
$sn-font-size-lg-5: 50px;
$sn-font-size-lg-6: 65px;

//== Containers
$max-content-width: 1920px;
$fixed-content-width: 970px;

//== Header Nav
$header-top-height-m: 26px; // `.tippy-top` bar on mobile & tablet
$header-nav-height-m: 67px; // `.page-header` bar - mobile
$header-nav-height-d: 108px; // `.page-header` bar - desktop

$header-nav-padding-vertical-d: 19px;

$skip-nav-link-height: 24px;

// Height of main nav component on mobile
// `.page-header` bar
$header-height-m: $header-top-height-m + $header-nav-height-m;

// Height of main nav component on Desktop
// `.page-header` bar
$header-height-d: $header-nav-height-d;

$wa-admin-bar-height: 64px;

//== Assets
$url-assets: 'https://www.sleepnumber.com/assets';

/* SLICK CAROUSEL VARIABLES
------------------------------------------------------------------------------------------------- */

$slick-font-path: '/webfonts/slick/';
$slick-loader-path: '~slick-carousel/slick/';
$slick-dot-color-active: white;

$slick-dot-size: 25px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;

/* BOOTSTRAP VARIABLE OVERRIDES

  To override a bootstrap variable:
  1. Copy/paste it here from ~bootstrap/scss/variables
  2. Change the value
  3. Remove the `!default`

  To add or update a value in a bootstrap map:
  $theme-colors: (
    "primary": #06c, // updated existing
    "my_soul": #000  // added new
  );

  To remove a value in a bootstrap map (not recommended) see:
  https://getbootstrap.com/docs/4.4/getting-started/theming/#variable-defaults

  The sub-section names match names from ~bootstrap/scss/variables
------------------------------------------------------------------------------------------------- */

//== Colors
$blue: $brand-color-secondary-blue;
$red: $global-color-promo-red;
$light: $global-color-gray-100;
$dark: $global-color-gray-600;
// switching from gray to white, requires fixing .btn-secondary text color
$secondary: $global-color-white;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'light-blue': $global-color-blue-50,
  ),
  $theme-colors
);

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'light-blue': $global-color-blue-50,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

//== Body
$body-color: $global-color-black;

//== Links
$link-hover-color: $brand-color-primary-blue;

//== Spacing

$spacers: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 40px,
  7: 60px,
);

//== Grid Breakpoints
// Same as default BS except for lg
// prettier-ignore
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px, // bumped up from 992px
  xl: 1200px,
);

//== Grid Containers
// Same as default BS except for lg
// prettier-ignore
$container-max-widths: (
  md: 1170px
);

//== Grid columns
// BS default was 30px
// Small BS bug fixed by us here. 1rem for us is 16px, but the default gutter
// is 30px, divided by 2 is 15px (used as padding on each side of most things.)
// A .row has -15px margin (gutter / 2) which bumps us out out past our padded
// containers by 1 extra pixel.  Reproducible on getbootstrap.com with same rem value.
//
// Fixed by defining gutter width using the same variable that the paddings are using.
$grid-gutter-width: 2rem;

//== Components
$border-radius-sm: 2px;
$border-radius: 4px;
$border-radius-lg: 8px;

$btn-border-radius: 8px;
$btn-border-radius-lg: 8px;
$btn-border-radius-sm: 8px;

$dropdown-border-radius: 8px;

$component-active-color: $blue;
$component-active-bg: $brand-color-primary-blue;

//== Typography
$font-family-sans-serif: $custom-font-book;
$font-family-monospace: Monaco, Menlo, Consolas, 'Courier New', monospace;

$font-size-base: 1rem; // 16px
$font-size-lg: 1.125rem; // 18px
$line-height-base: 1.5; // 14px * 1.25 = 21, so content box height = 21px
$line-height-lg: 1.5; // 18px * 1.5 = 27, so content box height = 27px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

//== Buttons + Forms
$input-btn-font-size: 0.875rem; // 14px;
$input-btn-padding-y: 0.78125rem; // 12.5px
$input-btn-padding-x: 1rem; // 16px
$input-btn-line-height: $line-height-base;
$btn-white-space: nowrap;

// Make the sm variant 32px tall but keep the 14px font-size.
$input-btn-font-size-sm: $font-size-base;
$input-btn-padding-x-sm: $input-btn-padding-x;
$input-btn-padding-x-lg: $input-btn-padding-x;
$input-btn-line-height-sm: $line-height-base;

//== Forms
$input-color: $global-color-black;
$input-border-color: $global-color-gray-600;
$input-focus-border-color: $brand-color-secondary-blue;
$custom-control-indicator-checked-disabled-bg: #fff;

$input-border-radius: $btn-border-radius;
$input-border-radius-lg: $btn-border-radius-lg;
$input-border-radius-sm: $btn-border-radius-sm;

//== Navbar
$navbar-light-color: $global-color-black;

//== Dropdowns
$dropdown-border-color: $global-color-gray-600;
$dropdown-divider-bg: $global-color-gray-300;
$dropdown-link-color: $global-color-black;
$dropdown-link-hover-color: $global-color-black;
$dropdown-link-hover-bg: darken($global-color-white, 10%);
$dropdown-link-active-color: $global-color-black;
$dropdown-link-active-bg: $global-color-gray-300;

//== Modals
$modal-md: 600px;
$zindex-modal: 107159;
$modal-content-border-radius: 2px;

/* BOOTSTRAP VARIABLES IMPORT (must be import AFTER we override the defaults)
------------------------------------------------------------------------------------------------- */
@import '~bootstrap/scss/variables';
